import React, { useState, useEffect } from 'react';
import App from 'src/components/App';
import { History, navigate } from '@reach/router';
import Input from 'src/components/Input';
import cn from 'classnames';
import { useAuth0 } from 'src/utils/auth';
import api from 'src/api';

// tslint:disable-next-line: no-var-requires
const tick = require('../icons/fill/tick.svg');
// tslint:disable-next-line: no-var-requires
const cross = require('../icons/fill/cross.svg');

const ChangePasswordPage = () => {
  const [password, setPassword] = useState({
    value: '',
    touched: false,
    error: '',
  });
  const [confirmPassword, setConfirmPassword] = useState({
    value: '',
    touched: false,
    error: '',
  });
  const hasEnoughLength = /.{8,}/.test(password.value);
  const isContainValidChar = /(?=.*[A-Z])(?=.*[a-z]).*/.test(password.value);

  const [loading, setLoading] = useState(false);

  const { user = {}, loading: userLoading, logout } = useAuth0();

  useEffect(() => {
    const isUserFromDatabase = user?.connectionType === 'auth0';
    if (!userLoading && !isUserFromDatabase) {
      navigate('/profile');
    }
  }, [user, userLoading]);

  useEffect(() => {
    const error = password.touched && !password.value ? 'Password is required' : '';
    setPassword(prevState => ({ ...prevState, error }));
  }, [password.value]);

  useEffect(() => {
    if (!confirmPassword.touched) return;
    let error = '';
    if (!confirmPassword.value) error = 'Confirm Password is required';
    else if (confirmPassword.value !== password.value) error = 'Confirm Password is not same as Password';
    setConfirmPassword(prevState => ({
      ...prevState,
      error,
    }));
  }, [confirmPassword.value, password.value]);

  function changePassword() {
    let hasError = false;

    if (!password.value) {
      setPassword(prevState => ({
        ...prevState,
        error: 'Password is required',
      }));
      hasError = true;
    }

    if (!confirmPassword.value) {
      setConfirmPassword(prevState => ({
        ...prevState,
        error: 'Confirm Password is required',
      }));
      hasError = true;
    }

    if (hasError) return;

    const isPasswordValid = hasEnoughLength && isContainValidChar && !confirmPassword.error;
    if (!isPasswordValid) return;

    setLoading(true);
    api
      .changePassword(password.value)
      .then(() => {
        logout();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="wrapper">
      <style jsx={true}>{`
        .wrapper {
          @p: .flex1, .flex, .flexColumn, .mhAuto, .h100, .overflowYScroll;
        }
        h1 {
          @p: .fw6, .mb60, .hf30;
        }
        .container {
          @p: .w100, .mhAuto, .bbox, .pa60, .bgBlack04;
          max-width: 85rem;
          height: 100%;
        }
        .content {
          display: flex;
        }
        .form-item + .form-item {
          @p: .mt16;
        }
        .confirm-password-validation {
          @p: .mt6, .f12;
          color: #f44336;
        }
        .password-requirement {
          @p: .ml60;
          padding: 0;
        }
        .password-requirement__title {
          @p: .f20, .black60, .mb16;
          text-align: left;
        }
        .submit-button {
          @p: .ph16, .pv10, .mt20;
          background-color: $primary;
          color: #fff;
          border: 0;
          border-radius: 2px;
        }
        .submit-button:hover {
          cursor: pointer;
        }
        .submit-button:focus {
          border: none;
          outline: none;
        }
        @media (max-width: 500px) {
          .container.container {
            @p: .pa25;
          }
        }
      `}</style>
      <div className="container">
        <h1>Change Password</h1>
        <div className="content">
          <form
            className="change-password-form"
            onSubmit={e => {
              e.preventDefault();
              changePassword();
            }}
          >
            <div className="form-item">
              <Input value={user?.email || ''} label="Email" readOnly={true} inputStyle={{ width: '300px' }} />
            </div>
            <div className="form-item">
              {' '}
              <Input
                value={password.value}
                onChange={value => {
                  setPassword(prevState => ({
                    ...prevState,
                    value,
                    touched: true,
                  }));
                }}
                label="Password"
                placeholder="Enter password"
                password={true}
                inputStyle={{ width: '300px' }}
              />
              {password.error && <p className="confirm-password-validation">{password.error}</p>}
            </div>
            <div className="form-item">
              <Input
                value={confirmPassword.value}
                onChange={value => {
                  setConfirmPassword(prevState => ({
                    ...prevState,
                    value,
                    touched: true,
                  }));
                }}
                label="Confirm Password"
                placeholder="Enter password again"
                password={true}
                inputStyle={{ width: '300px' }}
              />
              {confirmPassword.error && <p className="confirm-password-validation">{confirmPassword.error}</p>}
            </div>
            <button type="submit" className="submit-button">
              {loading ? 'Changing...' : 'Change Password'}
            </button>
          </form>
          <section className="password-requirement">
            <h2 className="password-requirement__title">your password should</h2>
            <ul className="password-requirement__list">
              <ValidateListItem isValid={hasEnoughLength}>Must at least 8 character</ValidateListItem>
              <ValidateListItem isValid={isContainValidChar}>Contains Capital and lower letter</ValidateListItem>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

interface ValidateListItemProps {
  children: string;
  isValid: boolean;
}
const ValidateListItem: React.FC<ValidateListItemProps> = ({ children, isValid }) => {
  const icon = isValid ? tick : cross;
  return (
    <li className="wrapper">
      <style jsx={true}>
        {`
          .wrapper {
            @p: .mb10;
            display: flex;
            align-items: center;
          }
          .icon {
            @p: .mr4;
            width: 15px;
            height: 15px;
          }
          .text.inValid {
            color: #f44336;
          }
        `}
      </style>
      <img src={icon} className="icon" />
      <span className={cn('text', { inValid: !isValid })}>{children}</span>
    </li>
  );
};

interface Props {
  history: History;
}
export default ({ history }: Props) => {
  return (
    <App history={history}>
      <ChangePasswordPage />
    </App>
  );
};
