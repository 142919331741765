import React, { ChangeEvent, CSSProperties } from 'react';

interface Props {
  label: string;
  value: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  password?: boolean;
  readOnly?: boolean;
  inputStyle?: CSSProperties;
}
const Input: React.FC<Props> = ({ label, value, onChange, placeholder, password, readOnly, inputStyle }) => {
  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;
    onChange(event.target.value);
  };
  const inputType = password ? 'password' : 'text';
  return (
    <div className="input-wrapper">
      <style jsx={true}>{`
        .input-wrapper + .input-wrapper {
          @p: .mt16;
        }
        .label {
          @p: .mb10, .black60, .b;
          display: block;
        }
        .input {
          @p: .pa10;
          min-width: 200px;
          border: 1.5px solid rgba(0, 0, 0, 0.2);
        }
        .input::placeholder {
          @p: .black30;
        }
        .input:focus:not(:read-only) {
          border-color: $primary;
        }
        .input:read-only {
          @p: .bgBlack02, .black50;
        }
      `}</style>
      <label className="label">{label}</label>
      <input
        className="input"
        style={inputStyle}
        type={inputType}
        onChange={onChangeValue}
        value={value}
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </div>
  );
};

export default Input;
